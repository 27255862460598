"use client";

import { EmptyState } from "@spheron/ui-library";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="h-[500px]">
      <EmptyState
        entityTitle="Something went Wrong"
        showButton
        buttonText="Retry"
        buttonDisabled={false}
        onClick={() => reset()}
        showButtonIcon={false}
      />
    </div>
  );
}
